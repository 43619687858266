<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('core.license')" class="settings">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
              <span>{{ $t('core.Set_your_license_code') }}</span>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow v-if="licenseLoading === true">
            <CCol cols="12" lg="12">
              <loadingSpinner mode="auto" :content="$t('core.Loading_settings')"/>
            </CCol>
          </CRow>
          <CRow v-if="licenseLoading === false" class="m-0">
            <CCol cols="12" lg="12">
              <CRow>
                <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0 pb-0">
                  <div class="d-flex align-items-center mb-2">
                    <span class="mr-2">{{ $t('core.Current_license_code') }}:</span>
                    <span v-if="licenseData.license_code" class="d-flex align-items-center">
                      <span>{{licenseData.license_code}} ({{licenseData.license_name}})</span>
                      <CBadge v-if="licenseData.activated === 1" color="success" class="ml-1">{{ $t('common.Active') }}</CBadge>
                      <CBadge v-else class="inactive ml-1">{{ $t('common.Inactive') }}</CBadge>
                    </span>
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="mr-2">{{ $t('core.Max_user_amount') }}:</span>
                    <span v-if="licenseData.max_users" class="d-flex align-items-center">
                      <span>{{licenseData.max_users}} {{$t('common.users')}}</span>
                    </span>
                  </div>                                           
                </CCol>
              </CRow>
              <CRow v-if="checkPermission('core.license.update')">
                <CCol cols="12" lg="12" class="pb-0">
                  <CButton color="primary" @click="licenseModal = true; resetLicenseData();"><i class="fas fa-pen mr-1"/>{{ $t('core.Update_license_code') }}</CButton>
                </CCol>
              </CRow>
            </CCol>    
          </CRow>
        </CCardBody>
      </CCard>    
    </CCol>

    <b-modal class="target pl-0 pr-0" :can-cancel="[]" :active.sync="licenseModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">            
          {{ $t('core.Update_license_code') }}
        </CCardHeader>
        <CCardBody>          
          <CRow class="p-0">
            <CCol cols="12" lg="12" class="pt-0 pb-0">            
              <CInput class="mb-0" type="text" :label="$t('core.Enter_license_code')" min="0" max="10" step="0.1" v-model="licenseModalData.license_code"/>              
              <p v-if="licenseErrorMessage !== null" class="error mt-2">{{licenseErrorMessage}}</p>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <div>
            <CButton color="primary" @click="updateLicense(licenseModalData);" :disabled="licenseModalData.license_code === null || licenseModalData.license_code === ''">
              <i class="fas fa-save mr-1"/>{{ $t('Update') }}
            </CButton>
            <CButton color="secondary" @click="licenseModal = false; resetLicenseData()">
              <i class="fas fa-times mr-1"/>{{ $t('Cancel') }}
            </CButton>
          </div>                  
        </CCardFooter>
      </CCard>
    </b-modal>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

export default {
  name: 'Theming',
  components: {
    loadingSpinner,
    noPermission
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      licenseData: {},
      licenseModal: false,
      licenseModalData: { license_code: null },
      licenseErrorMessage: null,
      licenseLoading: false,
      licenseLoaded: false,
    }
  },
  methods: {
    getLicenseData() {
      // Start the loader
      if(this.licenseLoaded = false) this.licenseLoading = true;
      // Get the license data
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/license')
      .then(res => {
        this.licenseData = res.data.data;
        // Stop the loader
        this.licenseLoading = false;
        // Update the licenseLoaded data
        this.licenseLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateLicense(licenseModalData) {
      let params = {};
      params = licenseModalData;
      // Reset the license error message
      this.licenseErrorMessage = null;
      // Update the license for the company
      axios.put(process.env.VUE_APP_API_URL + '/v1/core/license', params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('core.License_code_updated'), type: 'is-success', duration: 2000 });
        // Get the updated license data
        this.getLicenseData();
        // Close the modal
        this.licenseModal = false;
        // Reset the license error message
        this.licenseErrorMessage = null;
      })
      .catch(err => {
        // Show correct error message according to error
        if(err.response.data.error === 'Invalid license code') {
          this.licenseErrorMessage = this.$t('core.Invalid_license_code');
        } else if(err.response.data.error === 'Same license') {
          this.licenseErrorMessage = this.$t('core.Same_license');
        }
      });
    },    
    resetLicenseData () {
      this.licenseErrorMessage = null;
      this.licenseModalData = {
        license_code: null
      };
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function() {
    this.getPlatformPermissions();
    this.getLicenseData();
    this.resetLicenseData();
  }
}
</script>
